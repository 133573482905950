// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Home_contenido__uEfBU{
  margin-left: 20%;

}
@media only screen and (min-width: 1810px) and (max-width: 2200px) {
  .Home_contenido__uEfBU{
    margin-left:5%
  }

  }
@media only screen and (min-width: 1600px) and (max-width: 1800px) {
  .Home_contenido__uEfBU{
    margin-left:18%
  }

  }

@media only screen and (min-width: 825px) and (max-width: 1400px) {
.Home_contenedorPrincipal__gZeto{

  margin-left: 8%;
}


}
`, "",{"version":3,"sources":["webpack://./src/styles/Home.module.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;;AAElB;AACA;EACE;IACE;EACF;;EAEA;AACF;EACE;IACE;EACF;;EAEA;;AAEF;AACA;;EAEE,eAAe;AACjB;;;AAGA","sourcesContent":["\n.contenido{\n  margin-left: 20%;\n\n}\n@media only screen and (min-width: 1810px) and (max-width: 2200px) {\n  .contenido{\n    margin-left:5%\n  }\n\n  }\n@media only screen and (min-width: 1600px) and (max-width: 1800px) {\n  .contenido{\n    margin-left:18%\n  }\n\n  }\n\n@media only screen and (min-width: 825px) and (max-width: 1400px) {\n.contenedorPrincipal{\n\n  margin-left: 8%;\n}\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contenido": `Home_contenido__uEfBU`,
	"contenedorPrincipal": `Home_contenedorPrincipal__gZeto`
};
export default ___CSS_LOADER_EXPORT___;
