import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Spiner } from "../Spiner";
import { IoArrowBackCircleOutline, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import moment from "moment-timezone";
import { BiSearch } from "react-icons/bi";
import {
  clearDetail,
  clearMovUser,
  transaccion_detalle,
  conciliar_transfer,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import ConciliacionDetalle from "./ConciliacionDetalle";
import s from  "../../styles/Users.module.css"
const Detalle_Tranferencia = ({ id, pagina, setPagina, closeModal }) => {
  const data = useSelector((state) => state.transaccion_detail);
  const [loading, setloading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    getTransDetail();
    // console.log(id)
    return () => {
      dispatch(clearDetail());
      setPagina(pagina);
    };
  }, []);
  const getTransDetail = async () => {
    try {
      await dispatch(transaccion_detalle(id));
      return;
    } catch (error) {}
  };
  const conciliar = async () => {
    try {
      console.log(data);
      const res = await dispatch(conciliar_transfer({ id: id }));
      if (res.status === 200) {
        setOpenModal(true);
      }
      //console.log(res)
    } catch (error) {
      console.log(error);
    }
  };
  
  function closeModalDetail() {
    setOpenModal(false)
  
  }
  return (
    <div className="">
      {!data?.length ? (
        <div className="alert alert-warning" role="alert">
          No se encontraron datos.
          <BiSearch style={{ fontSize: "30px" }} />
        </div>
      ) : (
        <div
          className="card"
          style={{
            maxWidth: "1100px ",

            fontSize: "16px",
          }}
        >
          <Link
            to={"/list/transacciones"}
            onClick={closeModal}
            style={{ padding: 8 }}
          >
            <IoArrowBackCircleOutline
              style={{ fontSize: "30px", color: "orange", marginLeft: 10 }}
            />
          </Link>
          <div
            className="card-header p-3"
            style={{ backgroundColor: "var(--naranja)", color: "#fff" }}
          >
            <h5>Detalle de la Operación</h5>
          </div>
          <div className="row p-3">
            <div className="card-body p-2">
              <h5 className="card-title mb-3">
                Datos generales de la transacción
              </h5>
              <div className="row">
                <div className="col-md-4">
                  <b className="fs-6">Fecha y hora:</b>
                  <p className="card-text">
                    {moment
                      .utc(data[0]?.start_date)
                      .tz("America/Argentina/Buenos_Aires")
                      ?.format("DD-MM-YYYY") +
                      " " +
                      data[0]?.start_date?.slice(11, 16)}{" "}
                    hs
                  </p>
                  <b className="fs-6">Código de transacción:</b>
                  <p className="card-text ">{data[0]?.transaction_ids}</p>
                </div>
                <div className="col-md-4">
                  <b className="fs-6">Monto:</b>
                  <p className="card-text">
                    {data[0]?.charge?.value?.currency} ${" "}
                    {data[0]?.charge?.value?.amount}
                  </p>
                  <p>{"Estado: " + data[0]?.status}</p>
                  <p>
                    {data[0]?.status_description
                      ? "Observaciones: " + data[0]?.status_description
                      : ""}
                  </p>
                </div>
              </div>
            </div>
            <hr style={{ borderTop: "1px dashed black" }} />
            <div className="card-body p-2">
              <h5 className="card-title mb-3">Datos de Origen</h5>
              <b className="fs-6">Cuenta:</b>
              <p className="fs-6">
                {"CVU: " + data[0]?.details?.origin_debit?.cvu}
              </p>
              <b className="fs-6">CUIL/CUIT:</b>
              <p className="card-text">
                {data[0]?.details?.origin_debit?.cuit}
              </p>
            </div>
            <hr style={{ borderTop: "1px dashed black" }} />

            <div className="card-body p-2">
              <h5 className="card-title mb-3">Datos de Destino</h5>
              <div className="row">
                <div className="col-md-4">
                  <b className="fs-6">Usuario:</b>
                  <p className="card-text">{data[0]?.counterparty?.name}</p>
                  <b className="fs-6">CUIL:</b>
                  <p className="card-text">{data[0]?.counterparty?.id}</p>
                </div>
                <div className="col-md-4">
                  <b className="fs-6">CVU:</b>
                  <p className="card-text">
                    {data[0]?.counterparty?.account_routing?.address}
                  </p>
                  <b className="fs-6">Banco:</b>
                  <p className="card-text">
                    {data[0]?.counterparty?.bank_routing?.scheme}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button className="btn btn-secondary" onClick={conciliar}>
            Conciliar
          </button>
          <Modal
            isOpen={openModal}
            className={s.modal}
            //onRequestClose={}
            overlayClassName={s.Overlay} //fondo atras
            contentLabel="Example Modal"
          >
            <ConciliacionDetalle
             closeModal={closeModalDetail}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Detalle_Tranferencia;
