import React from "react";

import { FaUserCircle } from "react-icons/fa";
import { MdExitToApp } from "react-icons/md";
import {  useNavigate} from "react-router-dom";
import styles from "../../styles/Navbar.module.css";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { LogOut } from "../../redux/actions";


const Nav = () => {
  const [loggedInUser, setLoggedInUser] = useState(null);
  const usuario = localStorage.getItem("user-log");

    const dispatch = useDispatch()
    const navigate = useNavigate()
  useEffect(() => {
    if (usuario) {
      setLoggedInUser(JSON.parse(usuario));
    }

  }, [usuario]);

  const handleLogout = () => {

    dispatch(LogOut());
    localStorage.removeItem("user-log");
    navigate("/login")

  };


  return (
    <nav>
      <div className={styles.containerNav}>
        <div className={styles.logo_user}>
       
        <div className={styles.user}>
          <FaUserCircle
            title="Usuario conectado"
            style={{ fontSize: "30px", marginRight: "5px", color: "orange" }}
          />
          <p>
            {" "}
            {loggedInUser
              ? `Usuario: ${
                  loggedInUser?.data_user.name 
                }`
              : ""}
          </p>
        </div>

        </div>
       
        <MdExitToApp
        onClick={handleLogout}
          title="Salir"
          style={{
            fontSize: "35px",
            marginLeft: "5px",
            color: "orange",
            cursor: "pointer",
          }}
        />
      </div>
    </nav>
  );
};

export default Nav;
